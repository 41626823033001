import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Configuration for time conversion
const CONFIG = {
  irlToInGameMultiplier: 16,
  startDate: new Date("2024-01-01T00:00:00Z"),
  inGameYearLength: 120,
  months: [
    { name: "Ehrvalis", days: 30 },
    { name: "Lilaren", days: 30 },
    { name: "Fortavris", days: 30 },
    { name: "Astrevia", days: 30 },
  ],
};

const convertIrlToInGame = (irlDate) => {
  const elapsedMs = irlDate - CONFIG.startDate;
  const elapsedDays = elapsedMs / (1000 * 60 * 60 * 24);
  const inGameDays = elapsedDays * CONFIG.irlToInGameMultiplier;
  const inGameYears = Math.floor(inGameDays / CONFIG.inGameYearLength);
  const remainingDays = inGameDays % CONFIG.inGameYearLength;

  let dayCount = Math.floor(remainingDays);
  let monthIndex = 0;
  for (let i = 0; i < CONFIG.months.length; i++) {
    if (dayCount < CONFIG.months[i].days) {
      monthIndex = i;
      break;
    }
    dayCount -= CONFIG.months[i].days;
  }

  const remainingHours = (remainingDays % 1) * 24;
  const hours = Math.floor(remainingHours);
  const minutes = Math.floor((remainingHours % 1) * 60);
  const seconds = Math.floor(((remainingHours % 1) * 60 % 1) * 60);

  return {
    year: inGameYears + 1,
    month: CONFIG.months[monthIndex].name,
    day: dayCount + 1,
    hours,
    minutes,
    seconds,
  };
};

const convertInGameToIrl = (
  inGameYear,
  inGameMonth,
  inGameDay,
  hours = 0,
  minutes = 0,
  seconds = 0
) => {
  let totalInGameDays = (inGameYear - 1) * CONFIG.inGameYearLength + (inGameDay - 1);

  for (let i = 0; i < inGameMonth - 1; i++) {
    totalInGameDays += CONFIG.months[i].days;
  }

  totalInGameDays += hours / 24 + minutes / 1440 + seconds / 86400;

  const irlDays = totalInGameDays / CONFIG.irlToInGameMultiplier;
  const irlDate = new Date(CONFIG.startDate.getTime() + irlDays * 24 * 60 * 60 * 1000);
  return irlDate;
};

const TimeConverter = () => {
  const [irlDate, setIrlDate] = useState(new Date());
  const [convertedInGameDate, setConvertedInGameDate] = useState(
    convertIrlToInGame(new Date())
  );
  const [inGameYear, setInGameYear] = useState(1);
  const [inGameMonth, setInGameMonth] = useState(1);
  const [inGameDay, setInGameDay] = useState(1);
  const [inGameHours, setInGameHours] = useState(0);
  const [inGameMinutes, setInGameMinutes] = useState(0);
  const [inGameSeconds, setInGameSeconds] = useState(0);
  const [convertedIrlDate, setConvertedIrlDate] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [conversionDialogOpen, setConversionDialogOpen] = useState(false);
  const [conversionResult, setConversionResult] = useState(null);
  const [irlToInGame, setIrlToInGame] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setConvertedInGameDate(convertIrlToInGame(new Date()));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleIrlToInGame = () => {
    const result = convertIrlToInGame(irlDate);
    setConversionResult(result);
    setIrlToInGame(true);
    setConversionDialogOpen(true);
  };

  const handleInGameToIrl = () => {
    const result = convertInGameToIrl(
      inGameYear,
      inGameMonth,
      inGameDay,
      inGameHours,
      inGameMinutes,
      inGameSeconds
    );
    setConversionResult(result.toLocaleString());
    setIrlToInGame(false);
    setConversionDialogOpen(true);
  };

  return (
    <Box sx={{ p: 4, fontFamily: "Roboto, sans-serif" }}>
      <Typography variant="h4" gutterBottom>
        Ehrveillian Calendar
      </Typography>

      <Typography variant="h5" gutterBottom>
        Current In-Game Time: Year {convertedInGameDate.year}, {convertedInGameDate.month} {convertedInGameDate.day}{" "}
        {convertedInGameDate.hours.toString().padStart(2, "0")}:
        {convertedInGameDate.minutes.toString().padStart(2, "0")}:
        {convertedInGameDate.seconds.toString().padStart(2, "0")}
      </Typography>

      <Button variant="outlined" onClick={() => setInfoDialogOpen(true)} sx={{ mb: 2 }}>
        What is the Ehrveillian Calendar?
      </Button>

      <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)}>
        <DialogTitle>Ehrveillian Calendar</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            The Ehrveillian calendar divides the year into four months of 30 days each:
          </Typography>
          <ul>
            <li>
              <strong>Ehrvalis:</strong> Symbolizing leadership and beginnings, named after the Ehrveil family.
            </li>
            <li>
              <strong>Lilaren:</strong> Honoring Lilarreich and its cultural legacy.
            </li>
            <li>
              <strong>Fortavris:</strong> Celebrating resilience and military strength.
            </li>
            <li>
              <strong>Astrevia:</strong> Representing aspiration and progress.
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Convert IRL Date to In-Game Date</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="IRL Date and Time"
            type="datetime-local"
            value={irlDate.toISOString().slice(0, 16)}
            onChange={(e) => setIrlDate(new Date(e.target.value))}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleIrlToInGame}>
            Convert to In-Game Date
          </Button>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Convert In-Game Date to IRL Date</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="In-Game Year"
            type="number"
            value={inGameYear}
            onChange={(e) => setInGameYear(parseInt(e.target.value, 10))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="In-Game Month (1-4)"
            type="number"
            value={inGameMonth}
            onChange={(e) => setInGameMonth(parseInt(e.target.value, 10))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="In-Game Day"
            type="number"
            value={inGameDay}
            onChange={(e) => setInGameDay(parseInt(e.target.value, 10))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="In-Game Hours"
            type="number"
            value={inGameHours}
            onChange={(e) => setInGameHours(parseInt(e.target.value, 10))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="In-Game Minutes"
            type="number"
            value={inGameMinutes}
            onChange={(e) => setInGameMinutes(parseInt(e.target.value, 10))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="In-Game Seconds"
            type="number"
            value={inGameSeconds}
            onChange={(e) => setInGameSeconds(parseInt(e.target.value, 10))}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="secondary" onClick={handleInGameToIrl}>
            Convert to IRL Date
          </Button>
        </AccordionDetails>
      </Accordion>

      <Dialog open={conversionDialogOpen} onClose={() => setConversionDialogOpen(false)}>
        <DialogTitle>Conversion Result</DialogTitle>
        <DialogContent>
          {irlToInGame ? (
            <Typography>
              Year {conversionResult?.year}, {conversionResult?.month} {conversionResult?.day},{" "}
              {conversionResult?.hours?.toString().padStart(2, "0")}:
              {conversionResult?.minutes?.toString().padStart(2, "0")}:
              {conversionResult?.seconds?.toString().padStart(2, "0")}
            </Typography>
          ) : (
            <Typography>IRL Date: {conversionResult}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConversionDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TimeConverter;
