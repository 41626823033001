import React, { useState, useEffect } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { getPlayerList } from './players/apiFunctions';
import { backendURL } from '../App';

const navTheme = createTheme({
    palette: {
        primary: {
            main: '#c7c8c9'
        },
        text: {
            secondary: '#979899'
        }
    }
});

function PlayerList(props) {
    const [players, setPlayers] = useState([]);
    const [searchValue, setSearchValue] = useState(null);

    useEffect(() => {
        getPlayerList(setPlayers);
    }, []);

    const handleSelection = (event, value) => {
        if (value) {
            props.handleSelection(value);
        }
    };

    if (players.length === 0) {
        return <h1>Loading...</h1>;
    }
    if (players === null) {
        return <h1>ERROR</h1>;
    }

    return (
        <ThemeProvider theme={navTheme}>
            <Box sx={{ my: 4, width: '300px', mx: 'auto' }}>
                <Autocomplete
                    options={players}
                    getOptionLabel={(player) => player}
                    value={searchValue}
                    onChange={handleSelection}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search Players"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    renderOption={(props, player) => (
                        <Box
                            component="li"
                            {...props}
                            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                            <Avatar
                                alt={player}
                                src={`${backendURL}/public/${player}.png`}
                                sx={{ width: 24, height: 24 }}
                            />
                            {player}
                        </Box>
                    )}
                />
            </Box>
        </ThemeProvider>
    );
}

export default PlayerList;
